import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout";
import Home from "./pages/home";
import Documents from "./pages/documents";
import Login from "./pages/login";
import LoginSig from "./pages/login-sig";
import RequireAuth from "./components/require-auth";
import FileSig from "./pages/file-sig";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<RequireAuth />}>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                    </Route>
                    <Route path="/documents" element={<Layout />}>
                        <Route index element={<Documents />} />
                    </Route>
                </Route>

                <Route path="/login" element={<Login />}></Route>
                <Route path="/login-sig" element={<LoginSig />}></Route>
                <Route path="/" element={<Layout />}>
                    <Route path="/file-sig" element={<FileSig />}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
