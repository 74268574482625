import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import React, {useEffect, useState} from "react";
import jsSHA from "jssha";
import "./documents.css";
import { DateTime } from "luxon";
import {useCookies} from "react-cookie";
import rutoken from "@aktivco/rutoken-plugin/rutoken-plugin.min";
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import { useNavigate } from "react-router-dom";

function Documents() {
    const [fileList, setFileList] = useState([]);
    const [inputKey, setInputKey] = useState(0);
    const [cookies, setCookie] = useCookies(['user']);
    const [plugin, setPlugin] = useState(null);
    const [certificates, setCertificates] = useState([]);
    const [selectedCertificate, setSelectedCertificate] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // call api or anything
        rutoken.ready.then(function () {
            const isFirefox = !!window.navigator.userAgent.match(/firefox/i) && !window.navigator.userAgent.match(/seamonkey/i);

            if (window.chrome || isFirefox) {
                return rutoken.isExtensionInstalled();
            } else {
                return Promise.resolve(true);
            }
        }).then(function (result) {
            if (result) {
                return rutoken.isPluginInstalled();
            } else {
                console.log("Rutoken Плагин не установлен.");
            }
        }).then(function (result) {
            if (result) {
                return rutoken.loadPlugin();
            } else {
                console.log("Rutoken Плагин не установлен");
            }
        }).then(function (plugin) {
            //Можно начинать работать с плагином
            if (plugin) {
                try {
                    plugin.enumerateDevices();
                    plugin.enumerateKeys();

                    plugin.enumerateCertificates(0, 1).then(async res => {
                        if (res.length > 0) {
                            let result = [];
                            for (let i in res) {
                                const certificates = plugin.parseCertificate(0, res[i]);
                                const bios = await certificates.then((res) => {return res;});
                                let commonName = bios.subject.find((subject) => {return subject.rdn === 'commonName'});

                                if (i === '0') {
                                    let expires = new Date()
                                    expires.setTime(expires.getTime() + 1000000);
                                    setCookie('user', commonName.value, { path: '/',  expires});
                                }

                                const value = "Пользовательский | " + commonName.value + " | ";
                                result.push({key: res[i], value: value});
                            }

                            setCertificates(result);
                            setSelectedCertificate(res[0]);
                        }
                    })

                    setPlugin(plugin);
                } catch(error) {
                    console.log(error);
                    console.log("Что-то пошло не так");
                }
            }

            return plugin;
        }).then(function (plugin) {
            let password = cookies?.password;

            if (!password) {
                navigate('/login-sig', { replace: true });
            } else {
                password = password.toString();
            }

            plugin.login(0, password).then((res, error) => {
                return false;
            }, (reason) => {
                console.log(reason);
            });
        });
    }, []);

    const handleSignClick = async (i) => {
        const base64String = files[i].base64
            .replace('data:', '')
            .replace(/^.+,/, '');

        const contentType = 'application/pdf';
        const blob = base64StringToBlob(base64String, contentType);

        const options = {
            CMS: "",
            addEssCert: false,
            addSecurityProductsInfo: false,
            addSignTime: false,
            addSystemInfo: false,
            addUserCertificate: true,
            detached: true,
            useHardwareHash: false
        };

        plugin.sign(0, selectedCertificate, base64String, 1, options).then((res) => {
            const file = new File([res], files[i].name + '.sig');
            saveAs(file);
        });
    }

    const handleFileChange = async ({currentTarget: {files}}) => {
        if (files && files.length) {
            setFileList(existing => existing.concat(Array.from(files)));
        }

        const promise = getBase64(files[0]);
        const base64 = await promise;

        const base64String = base64
            .replace('data:', '')
            .replace(/^.+,/, '');

        const shaObj = new jsSHA("SHA-256", "B64");
        shaObj.update(base64String);

        files[0]['hash'] = shaObj.getHash("B64");
        files[0]['created_at'] = DateTime.fromMillis(files[0].lastModified).toFormat('ff');
        files[0]['base64'] = base64;

        // Reset the input by forcing a new one
        setInputKey(key => key + 1);
    }

    // 👇 files is not an array, but it's iterable, spread to get an array of files
    const files = fileList ? [...fileList] : [];

    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="certificate-container">
                        <select className="form-select" value={selectedCertificate} onChange={e => setSelectedCertificate(e.target.value)} >
                            {certificates.map((certificate) =>
                                <option value={certificate.key} key={certificate.key}>{certificate.value}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="file-upload-container">
                        <input key={inputKey} type="file" id="selectedFile" className="fileUpload"
                               onChange={handleFileChange}
                               accept="application/pdf"
                        />
                        <label htmlFor="selectedFile">
                            <a className="button btn btn-primary">Выберите файл</a>
                        </label>
                    </div>
                </div>
            </div>

            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Документ(Имя файла)</th>
                    <th className='hash-column'>Hash</th>
                    <th>Дата создания</th>
                    <th>Размер документа</th>
                    <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                {files.map((file, i) => (
                    <tr key={i}>
                        <td>{file.name}</td>
                        <td className='hash-column'>{file.hash}</td>
                        <td>{file.created_at}</td>
                        <td>{(file.size / (1024)).toFixed(2) + ' KB'}</td>
                        <td colSpan={2}>
                            <div className="action-button">
                                <Button onClick={() => handleSignClick(i)}>Подпись</Button>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
}

function getBase64(file) {
    return new Promise(function(resolve, reject) {
        var reader = new FileReader();
        reader.onload = function() { resolve(reader.result); };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

export default Documents;
