import PdfDocuments from "../../components/pdf-documents/pdf-documents";

function Index() {
    return (
        <div className="Home">
            <PdfDocuments></PdfDocuments>
        </div>
    );
}

export default Index;