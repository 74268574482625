import 'bootstrap/dist/css/bootstrap.min.css';
import Documents from "../../components/documents/documents";
function FileSig() {
    return (
        <div className="file-sig">
            <Documents></Documents>
        </div>
    );
}

export default FileSig;
