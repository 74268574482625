import 'bootstrap/dist/css/bootstrap.min.css';
import './main.css';
import { useContext, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import { useCookies } from 'react-cookie'
import rutoken from '@aktivco/rutoken-plugin/rutoken-plugin.min';

function LoginSig() {
    const {setAuth} = useContext(AuthContext)
    const userRef = useRef();
    const passwordRef = useRef();

    const [password, setPassword] = useState('');
    const [errMsg, setErrorMessage] = useState('');
    const [availableDevices, setAvailableDevices] = useState('');
    const [isLoginActive, setDisabled] = useState(true);
    const [plugin, setPlugin] = useState(null);

    const [cookies, setCookie] = useCookies(['user']);

    const navigate = useNavigate();

    useEffect(() => {
        // call api or anything
        rutoken.ready.then(function () {
            const isFirefox = !!window.navigator.userAgent.match(/firefox/i) && !window.navigator.userAgent.match(/seamonkey/i);

            if (window.chrome || isFirefox) {
                return rutoken.isExtensionInstalled();
            } else {
                return Promise.resolve(true);
            }
        }).then(function (result) {
            if (result) {
                return rutoken.isPluginInstalled();
            } else {
                setErrorMessage("Rutoken Плагин не установлен.");
            }
        }).then(function (result) {
            if (result) {
                return rutoken.loadPlugin();
            } else {
                setErrorMessage("Rutoken Плагин не установлен");
            }
        }).then(function (plugin) {
            //Можно начинать работать с плагином
            if (plugin) {
                try {
                    plugin.enumerateDevices().then(res => {
                        if (res.length > 0) {
                            setAvailableDevices('Доступные устройства:Rutoken ЭЦП ' + res[0]);
                            setDisabled(false);
                        } else {
                            setErrorMessage("Доступных устройств не найдено");
                        }
                    });

                    setPlugin(plugin);
                } catch(error) {
                    setErrorMessage("Что-то пошло не так");
                }
            }

            return plugin;
        });
    }, []);

    const handleSubmit = async (e) => {
        setErrorMessage('');

        let result = await handleLogin();

        e.preventDefault();

        return false;
    };

    const handleNavigate  = async (e) => {
        e.preventDefault();
        navigate('/login', { replace: true });
    }

    const handleLogin = async () => {
        plugin.login(0, password).then(res => {
            plugin.getDeviceInfo(0,1).then((result) => {
                if (typeof result === 'undefined') {
                    setErrorMessage('Pin код введен не правильно');
                }
            });

            let expires = new Date()
            expires.setTime(expires.getTime() + 1000000);

            setCookie('user', '', { path: '/',  expires});
            setCookie('password', password, { path: '/',  expires});
            setCookie('token', 'token', { path: '/',  expires});

            navigate('/file-sig', { state: { key: 123 }});

            return false;
        }, (reason) => {
            setErrorMessage('Pin код введен не правильно');
            console.log("What a reason!!!");
            console.log(reason);
        });
    }

    return (
        <div className="">
            <header>
                <a href="/">
                    <img id="header_logo" src="logo_white.png" alt="SignPush"></img>
                </a>
            </header>

            <div className="site-login">
                <div className="row jumbotron">
                    <div className="col-lg-12">
                        <h3 className="h3 mb-3 font-weight-normal" id="pass-label">Вход через ЭЦП токен</h3>
                        <form id="sign-form" method="post" onSubmit={handleSubmit}>
                            <input id="password" name="password" placeholder="PIN-код" type="password"
                                   ref={passwordRef}
                                   value={password}
                                   onChange={(e) => setPassword(e.target.value)}
                                   required
                                   className="modal-login_input"/>

                            <div className="row" align="center">
                                <p align="center">{availableDevices}</p>
                            </div>

                            <div className="row" align="center">
                                <p className="error-message text-danger" align="center">{errMsg}</p>
                            </div>

                            <button disabled={isLoginActive} className="btn btn-primary modal-login-submit" id="sign-in">
                                Войти
                            </button>
                            <button onClick={handleNavigate} className="btn btn-primary modal-login-submit" id="sign-in">
                                Вход через пароль
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            <div id="message-modal" className="modal-login">
                <div className="modal-login-dialog">
                    <div className="modal-login-content">
                        <div className="modal-login-header">
                            <a href="#close" title="Close" className="close">×</a>
                        </div>
                        <div className="modal-login_body">
                            <p className="modal-login_body__text message" align="center"></p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="verifyEmailModal" className="modal-login">
                <div className="modal-login-dialog">
                    <div className="modal-login-content">
                        <div className="modal-login-header">
                            <a href="#close" title="Close" className="close">×</a>
                        </div>
                        <div className="modal-login_body">
                            <div className="text-modal-wait">
                                <p className="modal-login_body__text" align="center">Пожалуйста активируйте аккаунт:</p>
                                <p className="modal-login_body__text" align="center"><a
                                    href="/resend-verification-email">Активировать</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginSig;
